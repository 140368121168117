<template>
  <rs-dialog :visible.sync="visible" :title="title">
    <rs-forms :callback="addSum" :data="sum" :type="type"></rs-forms>
  </rs-dialog>
</template>

<script>
import { regular } from './validate.js'

export default {
  data () {
    return {
      title: '添加收款',
      regular: regular,
      visible: false,
      id: '',
      sum: { oid: '', name: '', width: '', height: '', isbudget: '', price: '', number: '', unit: '' },
      type: [
        { label: '名称', type: 'name', rules: [{ required: true, message: '名称必填' }], active: true, keyList: ['防盗门', '推拉门', '双包套', '平开门', '淋浴隔断', '断桥窗', '复合实木门', '木门'] },
        {
          label: '宽度',
          type: 'width',
          rules: [{ pattern: regular.naturalNumber, message: regular.naturalNumberMsg, trigger: 'blur' }],
          active: true
        },
        {
          label: '高度',
          type: 'height',
          rules: [{ pattern: regular.naturalNumber, message: regular.naturalNumberMsg, trigger: 'blur' }],
          active: true
        },
        {
          label: '单价',
          type: 'price',
          rules: [
            { required: true, message: '单价必填' },
            { pattern: regular.naturalNumber, message: regular.naturalNumberMsg, trigger: 'blur' }],
          active: true
        },
        {
          label: '是否预算(1为是,0为否)',
          type: 'isbudget',
          rules: [
            { required: true, message: '位置必填' },
            { pattern: regular.boolean, message: regular.booleanMsg, trigger: 'blur' }],
          active: true
        },
        {
          label: '数量',
          type: 'number',
          rules: [
            { required: true, message: '数量必填' },
            { pattern: regular.sum, message: regular.sumMsg, trigger: 'blur' }],
          active: true,
          keyList: ['square', 'rice']
        },
        { label: '单位', type: 'unit', rules: [{ required: true, message: '单位必填' }], active: true, keyList: ['平方米', '米', '套', '个', '樘'] }
      ]
    }
  },
  methods: {
    addSum (data) {
      if ('id' in data) {
        this.$api.customOrderSum.update(data, function (data) {
          this.$emit('curd', data.data.oid)
          this.visible = false
        }.bind(this))
      } else {
        this.$api.customOrderSum.insert(data, function (data) {
          this.$emit('curd', data.data.oid)
          this.visible = false
        }.bind(this))
      }
    },
    handle (id, oid) {
      if (id) {
        this.title = '编辑订单账单'
        this.$api.customOrderSum.index({ id: id }, function (data) {
          this.sum = data.data
        }.bind(this))
      } else {
        this.sum = { oid: oid, name: '', width: '0', height: '0', isbudget: '0', price: '', number: '', unit: '' }
        this.title = '添加订单账单'
      }
      this.visible = true
    }
  }
}
</script>

<style>

</style>
