<template>
  <rs-dialog :visible.sync="visible" :title="title">
    <rs-forms :callback="addPay" :data="pay" :type="type"></rs-forms>
    {{pay}}
  </rs-dialog>
</template>

<script>
import { regular } from './validate.js'
export default {
  data () {
    return {
      item: null,
      title: '添加收款信息',
      pay: { name: '', money: '' },
      regular: regular,
      visible: false,
      type: [
        { label: '名义', type: 'name', rules: [{ required: true, message: '名义必填' }], active: true, keyList: ['定金(微信周)', '定金(李转周)', '定金(现金)', '全款结清(微信周)', '全款结清(李转周)'] },
        { label: '金额', type: 'money', rules: [{ pattern: regular.naturalNumber, message: regular.naturalNumberMsg, trigger: 'blur' }], active: true },
        { label: '日期', type: 'time', inputType: 'date', rules: [{ required: true, message: '日期必填' }] }
      ]
    }
  },
  methods: {
    handle (id, item, tid = null, type = null) {
      this.item = item
      if (id === 0) {
        this.title = '添加收款信息'
        this.pay = { tid: tid, type: type, name: '', money: '' }
      } else {
        this.title = '编辑收款信息'
        this.$api.pay.index({ id: id }, function (data) {
          this.pay = data.data
        }.bind(this))
      }
      this.visible = true
    },
    addPay (data) {
      if ('id' in data) {
        this.$api.pay.update(data, function (data) {
          if (data.code === 200) {
            this.$emit('customMoneyUpdate', 'money')
            this.visible = false
          }
        }.bind(this))
      } else {
        this.$api.pay.insert(data, function (data) {
          if (data.code === 200) {
            this.$emit('customMoneyUpdate', 'money')
            this.visible = false
          }
        }.bind(this))
      }
    }
  }
}
</script>

<style>

</style>
