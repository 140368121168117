<template>
  <div>
    <div style="position: absolute;top:0;right:20px">
      <rs-button is_sm @click="to_custom(1)">上一户</rs-button>
      <rs-button is_sm @click="to_custom(-1)">下一户</rs-button>
    </div>
    <!-- 客户区 及 功能区 -->
    <rs-row>
      <!-- 客户信息区 -->
      <rs-col :md="4" :lg="4">
        <div class="card card-profile">
          <div class="card-avatar">
            <a href="#pablo">
              <img
                class="img"
                style="height: 131px; width: 131px; background: #666"
              />
            </a>
          </div>
          <div class="card-body" style="max-height: 219px; height: 219px">
            <h4 class="card-title">
              {{ customInfo.name }}
              <rs-button
                is_sm
                color="info"
                style="position: absolute;top: 5px;border: 1px solid #fff;padding: 0 5px;height: 20px;border-radius: 10px;"
              >
                {{ statusStr[customInfo.status] }}
              </rs-button>
            </h4>
            <h5 class="card-title">{{ customInfo.tel }}</h5>
            <p class="card-description">{{ customInfo.address }}</p>
            <p class="card-description">{{ customInfo.time }}</p>
          </div>
          <ul class="nav nav-tabs edit" hidden>
            <li class="nav-item">
              <rs-button
                class="nav-link"
                is_link
                @click="$refs.custom.handle($route.query.id)"
              >
                <i class="material-icons">edit</i>
              </rs-button>
            </li>
          </ul>
        </div>
      </rs-col>
      <!-- 功能区 -->
      <rs-col :md="8" :lg="8">
        <rs-tabs title="功能" v-model="activeName" style="height: 315px">
          <rs-tab-pane label="收款" name="money" icon="attach_money">
            <table class="table">
              <tbody>
                <tr v-for="(value, index) in customMoney" :key="index">
                  <td>{{ value.name }}</td>
                  <td>{{ value.money }} {{ value.bid ? "*" : "" }}</td>
                  <td>{{ value.time }}</td>
                  <td>
                    <ul class="nav nav-tabs">
                      <li>
                        <rs-button
                          is_link
                          @click="$refs.customPay.handle(value.id, index)"
                        >
                          <i class="material-icons">edit</i>
                        </rs-button>
                      </li>
                      <li>
                        <rs-button
                          is_link
                          @click="customMoneyDelete(value.id, index)"
                        >
                          <i class="material-icons">delete</i>
                        </rs-button>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
            <ul class="nav nav-tabs edit" style="bottom: 0px" hidden>
              <li class="nav-item">
                <rs-button
                  is_link
                  @click="
                    $refs.customPay.handle(0, null, $route.query.id, 'custom')
                  "
                  ><i class="material-icons">add_box</i></rs-button
                >
              </li>
            </ul>
          </rs-tab-pane>
          <rs-tab-pane label="图片" name="image" icon="photo">
            <rs-row>
              <rs-col
                :lg="4"
                :md="6"
                v-for="(value, index) in customImage"
                :key="index"
              >
                <div class="card card-product">
                  <div
                    class="card-header card-header-image"
                    data-header-animation="true"
                  >
                    <img
                      class="img"
                      :src="value.img + '?x-oss-process=style/smart'"
                    />
                  </div>
                  <div class="card-body">
                    <div class="card-actions text-center">
                      <a
                        :href="value.img"
                        target="_black"
                        class="btn btn-default btn-link"
                      >
                        <i class="material-icons">art_track</i>
                      </a>
                      <a
                        class="btn btn-danger btn-link"
                        @click="deleteImage(value.id, index)"
                      >
                        <i class="material-icons">close</i>
                      </a>
                    </div>
                  </div>
                </div>
              </rs-col>
            </rs-row>
            <ul class="nav nav-tabs edit" style="bottom: 0px" hidden>
              <li class="nav-item">
                <span class="btn btn-info btn-round btn-file">
                  <span class="fileinput-new">选择图片</span>
                  <input type="file" @change="upload" />
                </span>
                <div
                  contenteditable="true"
                  @paste="pasteImg($event)"
                  class="textareaSty"
                >
                  粘贴图片
                </div>
              </li>
            </ul>
          </rs-tab-pane>
        </rs-tabs>
      </rs-col>
    </rs-row>
    <!-- 产品区 -->
    <rs-row>
      <rs-col>
        <rs-card icon="shopping_cart" title="产品列表">
          <template slot="tools">
            <rs-button
              is_link
              is_sm
              @click="$refs.customOrder.handle(0)"
              style="padding: 0"
            >
              <i class="material-icons">add_box</i>
            </rs-button>
            <rs-button is_link is_sm style="padding: 0" @click="getpdf">
              <i class="material-icons">insert_drive_file</i>
            </rs-button>
            <!-- <rs-button is_link is_sm style="padding: 0" @click="accountPdf">
              <i class="material-icons">insert_drive_file</i>
            </rs-button> -->
          </template>
          <rs-table :data="customOrder" :column="column">
            <template slot-scope="scope">
              <td class="td-actions text-right">
                <rs-button
                  color="success"
                  @click="$refs.order.handle(scope.row.id)"
                  >详情</rs-button
                >
                <rs-button
                  color="info"
                  @click="$refs.customOrder.handle(scope.row.id, scope.column)"
                  >编辑</rs-button
                >
                <rs-button
                  color="danger"
                  @click="customOrderDelete(scope.row.id, scope.column)"
                  >删除</rs-button
                >
              </td>
            </template>
          </rs-table>
        </rs-card>
      </rs-col>
    </rs-row>
    <!-- 增删改查 modal-->
    <div class="modal_div">
      <!--  用户信息 -->
      <custom @customUpdate="UpdateItem" ref="custom"></custom>
      <!--  用户金额信息 -->
      <custom-pay @customMoneyUpdate="UpdateItem" ref="customPay"></custom-pay>
      <!--  用户订单信息 -->
      <custom-order
        @customOrderUpdate="UpdateItem"
        ref="customOrder"
      ></custom-order>
      <!--  订单信息 -->
      <order ref="order"></order>
    </div>
  </div>
</template>

<script>
// CURD操作
import custom from "./curd/custom.vue";
import customPay from "./curd/customPay.vue";
import customOrder from "./curd/customOrder.vue";
import order from "./order.vue";
export default {
  components: { custom, customPay, customOrder, order },
  data() {
    return {
      // tab列表组
      activeName: "money",
      // 显示数据
      customInfo: {},
      customMoney: [],
      customTask: {},
      customOrder: [],
      customMessage: [],
      customImage: [],
      column: { weizi: "位置", product: "产品" },
      statusStr: ["未完成", "已完成", "已结账", "已核对"]
    };
  },
  mounted() {
    // 数据初始化
    this.init(this.$route.query.id);
  },
  watch: {
    $route() {
      this.init(this.$route.query.id);
    }
  },
  methods: {
    init(id, type) {
      // 初始化所有客户信息
      this.$api.custom.index(
        { id: id },
        function(data) {
          this.customInfo = data.data;
        }.bind(this)
      );
      this.$api.customOrder.list(
        { cid: id },
        function(data) {
          this.customOrder = data.data;
        }.bind(this)
      );
      this.$api.pay.customPay(
        { cid: id },
        function(data) {
          this.customMoney = data.data;
        }.bind(this)
      );
      this.$api.customImage.list(
        { cid: id },
        function(data) {
          this.customImage = data.data;
        }.bind(this)
      );
    },
    to_custom(num) {
      this.$router.push({
        path: "/custom/details",
        query: { id: parseInt(this.$route.query.id) + num }
      });
    },
    customOrderDelete(id, item) {
      this.$tools.swal.del(
        function() {
          this.$api.customOrder.del(
            { id: id },
            function(data) {
              if (data.code === 200) {
                this.customOrder.splice(item, 1);
              }
            }.bind(this)
          );
        }.bind(this)
      );
    },
    customMoneyDelete(id, item) {
      this.$tools.swal.del(
        function() {
          this.$api.pay.del(
            { id: id },
            function(data) {
              if (data.code === 200) {
                this.customMoney.splice(item, 1);
              }
            }.bind(this)
          );
        }.bind(this)
      );
    },
    // 更新显示数据
    UpdateItem(type, item, data) {
      switch (type) {
        case "money":
          this.$api.pay.customPay(
            { cid: this.$route.query.id },
            function(data) {
              this.customMoney = data.data;
            }.bind(this)
          );
          break;
        case "custom":
          if (item !== "add") {
            this.customInfo = data;
          }
          break;
        case "order":
          if (item === "add") {
            this.customOrder.push(data);
          } else {
            this.customOrder.splice(item, 1, data);
          }
          break;
        default:
          alert("error");
      }
    },
    upload(image) {
      this.$tools.upload.alioss(
        image.target.files[0],
        "Baihui/order/",
        function(result) {
          const url =
            "https://redscn.oss-cn-hangzhou.aliyuncs.com/" + result.name;
          this.$api.customImage.insert(
            { cid: this.$route.query.id, img: url },
            function(data) {
              this.customImage.push(data.data);
              console.log(result);
            }.bind(this)
          );
        }.bind(this)
      );
    },
    pasteImg(e) {
      console.log(e);
      const { items } = e.clipboardData;
      if (items.length) {
        for (const item of items) {
          if (item.type.indexOf("image") !== -1) {
            const file = item.getAsFile();
            // 上传文件
            this.$tools.upload.alioss(
              file,
              "Baihui/order/",
              function(result) {
                const url =
                  "https://redscn.oss-cn-hangzhou.aliyuncs.com/" + result.name;
                this.$api.customImage.insert(
                  { cid: this.$route.query.id, img: url },
                  function(data) {
                    this.customImage.push(data.data);
                    console.log(result);
                  }.bind(this)
                );
              }.bind(this)
            );
          }
        }
      }
    },
    deleteImage(id, item) {
      this.$tools.swal.del(
        function() {
          this.$api.customImage.del(
            { id: id },
            function(data) {
              if (data.code === 200) {
                this.customImage.splice(item, 1);
              }
            }.bind(this)
          );
        }.bind(this)
      );
    },
    getpdf() {
      window.open(
        "https://v2.sailinging.top/api/pdf/index?id=" +
          this.$route.query.id +
          "&token=" +
          localStorage.getItem("Authorization"),
        "_blank"
      );
    }
  }
};
</script>
<style scoped>
.card:hover [data-header-animation="true"] {
  transform: none;
}
.card-product:hover [data-header-animation="true"] {
  transform: translate3d(0, -50px, 0);
}
</style>
<style>
#custom_tool .card-body {
  max-height: 260px;
  height: 260px;
  overflow: auto;
}
.textareaSty {
  resize: none;
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  max-width: 50px;
  max-height: 40px;
  float: right;
  overflow: auto;
}
</style>
