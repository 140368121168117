<template>
  <rs-dialog :visible.sync="visible" :title="title">
    <h5>
      <small>ID:</small>{{ order.id }}<small>位置:</small> {{ order.weizi }}
      <small> 产品:</small> {{ order.product }} <small> 创建时间:</small>
      {{ order.createTime }}
      <rs-button
        @click="$refs.customOrderSum.handle(0, order.id)"
        is_sm
        class="float-right"
        color="success"
        >+ 销售</rs-button
      >
      <rs-button
        @click="$refs.customPay.handle(0, null, order.id, 'order')"
        is_sm
        class="float-right"
        color="success"
        >+ 开销</rs-button
      >
    </h5>
    <div class="modal_div">
      <custom-order-sum
        @curd="customOrderSum_CURD"
        ref="customOrderSum"
      ></custom-order-sum>
    </div>
    <h6 style="color:#eee">销售</h6>
    <rs-table :data="order.sumList" :column="sumColumn">
      <template slot-scope="scope">
        <td class="td-actions text-right">
          <rs-button
            @click="$refs.customOrderSum.handle(scope.row.id)"
            color="success"
            ><i class="material-icons">edit</i></rs-button
          >
          <rs-button @click="customOrderSum_delete(scope.row.id)" color="danger"
            ><i class="material-icons">close</i></rs-button
          >
        </td>
      </template>
      <tr slot="count">
        <td colspan="2"></td>
        <td colspan="2" class="text-right">数量合计:</td>
        <td>{{ sumSquare }}</td>
        <td class="text-right">金额合计:</td>
        <td colspan="2">{{ sumMoney }}</td>
      </tr>
    </rs-table>

    <h6 style="color:#eee">开销</h6>
    <rs-table :data="order.payList" :column="payColumn">
      <template slot-scope="scope">
        <td class="td-actions text-right">
          <rs-button @click="$refs.customPay.handle(scope.id)">编辑</rs-button>
        </td>
      </template>
      <tr slot="count">
        <td class="text-right">合计:</td>
        <td colspan="3">{{ sumPay }}</td>
      </tr>
    </rs-table>

    <h6 style="color:#eee">利润</h6>
    销售:{{ sumMoney }} 成本:{{ sumPay }} 利润:{{ sumMoney + sumPay }} 利润比:{{
      ((sumMoney + sumPay) / sumMoney) * 100
    }}%
    <custom-pay ref="customPay"></custom-pay>
  </rs-dialog>
</template>

<script>
import customOrderSum from "./curd/customOrderSum.vue";
import customPay from "./curd/customPay.vue";
export default {
  components: { customOrderSum, customPay },
  data() {
    return {
      title: "产品信息",
      visible: false,
      order: { weizi: "", product: "", payList: [], sumList: [] },
      payColumn: { name: "名义", money: "金额", time: "日期" },
      sumColumn: {
        name: "名称",
        width: "宽",
        height: "高",
        price: "单价",
        numberSum: "数量",
        unit: "单位",
        money: "金额"
      }
    };
  },
  methods: {
    handle(id) {
      this.order = { payList: [], sumList: [] };
      this.getCustomOrder(id);
      this.visible = true;
    },
    getCustomOrder(id) {
      this.$api.customOrder.index(
        { id: id },
        function(data) {
          this.order = data.data;
        }.bind(this)
      );
    },
    customOrderSum_CURD(id) {
      this.getCustomOrder(id);
    },
    customOrderSum_delete(id) {
      this.$tools.swal.del(
        function() {
          this.$api.customOrderSum.del(
            { id: id },
            function(data) {
              if (data.code === 200) {
                this.getCustomOrder(this.order.id);
              }
            }.bind(this)
          );
        }.bind(this)
      );
    }
  },
  computed: {
    sumMoney() {
      var money = 0;
      this.order.sumList.forEach(e => {
        money += parseInt(e.money);
      });
      return money;
    },
    sumSquare() {
      var square = 0;
      this.order.sumList.forEach(e => {
        square += parseFloat(e.number);
      });
      return square;
    },
    sumPay() {
      var pay = 0;
      this.order.payList.forEach(e => {
        pay += parseFloat(e.money);
      });
      return pay;
    }
  }
};
</script>

<style scoped>
h6 {
  color: rgb(255 255 255);
  background-color: #0095ff;
  padding: 5px;
  border-radius: 5px;
}
</style>
