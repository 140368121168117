<template>
  <rs-dialog :visible.sync="visible" :title="title">
    <rs-forms :callback="addOrder" :data="order" :type="type"></rs-forms>
  </rs-dialog>
</template>
<script>
export default {
  data () {
    return {
      title: '添加产品',
      item: null,
      order: { weizi: '', product: '' },
      visible: false,
      type: [
        {
          label: '位置',
          type: 'weizi',
          rules: [{ required: true, message: '位置必填' }],
          active: true,
          keyList: ['户外', '入户', '厨房', '阳台', '卫生间', '主卫', '公卫', '卧室', '主卧', '次卧', '儿童房', '女儿房']
        },
        {
          label: '产品',
          type: 'product',
          rules: [{ required: true, message: '产品必填' }],
          active: true,
          keyList: ['皇家金牌96断桥平开窗', '皇家金牌105断桥平开窗', '德诺特安全门', '好邻居安全门', '鼎萨木门', '金派丽门窗', '固美洁淋浴',
            '断桥窗', '防盗门', '推拉门', '平开门', '淋浴隔断', '木门']
        }
      ]
    }
  },
  methods: {
    handle (id, item) {
      this.item = item
      if (id === 0) {
        this.title = '添加产品'
        this.order = { cid: this.$route.query.id, weizi: '', product: '' }
      } else {
        this.title = '编辑产品'
        this.$api.customOrder.index({ id: id }, (data) => {
          this.order = data.data
        })
      }
      this.visible = true
    },
    addOrder (data) {
      if ('id' in data) {
        this.$api.customOrder.update(this.order, (data) => {
          if (data.code === 200) {
            this.$emit('customOrderUpdate', 'order', this.item, data.data)
            this.visible = false
          }
        })
      } else {
        this.$api.customOrder.insert(this.order, (data) => {
          if (data.code === 200) {
            this.$emit('customOrderUpdate', 'order', 'add', data.data)
            this.visible = false
          }
        })
      }
    }
  }
}
</script>
